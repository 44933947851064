<template>
    <div class="header">
        <div class="box fx">
            <img @click="$router.push('/')" src="shexie/img/logo.png" width="400px" alt="">
            <div>
                <div v-if="info.phone">
                    <span class="cursor" @click="$router.push('/my')">{{info.nickname || info.phone}}</span> | <span class="cursor" @click="logout">退出登录</span>
                </div>
                <div v-else>
                    <span class="cursor" @click="$router.push('/login')">登录</span> / <span class="cursor" @click="$router.push('/register')">注册</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {logout,users} from '@/api/login'
export default {
    data() {
        return {
            info:{}
        };
    },
    created() {
        if(localStorage.getItem('sheixetoken')){
            this.getUser()
        }
    },
    mounted() {
        
    },
    methods: {
        getUser(){
            users().then(res=>{
                if(res.code==200){
                    this.info = res.data
                }
            })
        },
        logout(){
            logout().then(res=>{
                if(res.code==200){
                    this.$message.success(res.msg)
                    localStorage.removeItem('sheixetoken');
                    localStorage.removeItem('oneFrom')
                    localStorage.removeItem('twoFrom')
                    localStorage.removeItem('threeFrom')
                    localStorage.removeItem('oneFrom1')
                    localStorage.removeItem('twoFrom1')
                    localStorage.removeItem('oneFrom2')
                    localStorage.removeItem('userid')
                    localStorage.removeItem('twoFrom2')
                    this.info = {}
                    this.$router.push('/jump')
                }
            })
        }
    }
};
</script>

<style scoped lang="scss">
    .header{
        background: #fff;
        height: 100px;
        line-height: 100px;
        .box{
            width: 1200px;
            margin: auto;
        }
    }
</style>
